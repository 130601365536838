.forgetpage {
  background-color: var(--primary-background);
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .forget {
    background-color: var(--secondary-background);
    width: 400px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 5px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 80%; */
    margin: auto;
    gap: 15px;
    .forminput {
      display: flex;
      flex-direction: column;
      position: relative;

      label {
        font-size: 20px;
        /* padding: 0px 10px; */
        text-align: start;
        color: var(--secondary-text-color);
        font-weight: 400;
      }

      input {
        padding: 10px;
        border: 2px solid var(--secondary-text-color);
        font-size: 17px;
        border-radius: 5px;
        height: 20px;
        /* width: 30vw; */
        /* border-bottom: 1px solid #a7bcff ; */
        /* margin: auto; */
        &::placeholder {
          color: var(--placeholder-text-color);
        }
      }
      .input-container {
        position: relative;
      }

      .input-container span {
        position: absolute;
        right: 10px; /* Adjust the distance from the right side as needed */
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .input-container input {
        padding: 10px;
        border: 2px solid var(--secondary-text-color);
        font-size: 17px;
        border-radius: 5px;
        height: 20px;
        width: 375px;
        /* width: 30vw ;
            /* margin: auto; */
        &::placeholder {
          color: var(--placeholder-text-color);
        }
      }
    }

    .btt {
      background-color: var(--button-color);
      color: var(--secondary-text-color);
      padding: 7px 10px;
      margin-left: auto;
      font-weight: 600;
      height: 35px;
      border: none;
      cursor: pointer;
      border-radius: 7px;
      width: 70px;
      font-size: 18px;
    }
    .bttn {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: flex-end;
      .btt1 {
        background-color: var(--button-color);
        color: var(--secondary-text-color);
        padding: 7px 10px;
        font-weight: bold;
        height: 35px;
        border: none;
        cursor: pointer;
        border-radius: 7px;
        /* width: 70px; */
        font-size: 18px;
      }
      .btt1:hover {
        color: var(--primary-text-color);
      }
    }
    .btt:hover {
      color: var(--primary-text-color);
    }
  }

  .forgetback {
    text-align: center;
    font-size: 18px;
    margin: 5px;
    color: var(--secondary-text-color);
    text-decoration: underline;
    text-decoration-color: var(--button-color);
  }
}

@media (max-width: 750px) {
  .forgetpage {
    .forget {
      width: 300px;
    }
    form {
      .forminput {
        .input-container input {
          width: 275px;
        }
      }
    }
  }
}
