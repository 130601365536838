    .addemployeewrapper {
        position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1; /* Sit on top */
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Center the modal */
    text-shadow: 0 0 black;
    box-shadow: 0 0px 5px;
    width:max-content; /* Full width */
    height: max-content; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    border-radius: 20px;
  
        /* height: 88%; */
        width: 530px;
        background-color: var(--secondary-background);
        /* margin: 20px 20px; */
        /* display: flex; */
        border-radius: 20px;
      
        .loginformwraper {
          padding: 20px;
          box-shadow: 0 0 5px;
          border-radius: 25px;
      
          .title {
            font-size: 30px;
            text-align: center;
            color: var(--secondary-text-color);
            margin: 0px;
            padding-bottom: 20px;
          }
          form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* width: 80%; */
            padding: 0px 20px;
            gap: 15px;
            margin: auto;
            .inputfield {
              display: flex;
              flex-direction: column;
              position: relative;
      
              label {
                font-size: 20px;
                /* padding: 10px 0px 0px 0px; */
                text-align: start;
                color: var(--secondary-text-color);
              }
      
              input {
                padding: 10px;
                border: 2px solid var(--secondary-text-color);
                font-size: 17px;
                border-radius: 5px;
                height: 20px;
                &::placeholder {
                  color: var(--placeholder-text-color);
                }
              }
              select {
                padding: 5px 10px;
                border: none;
                font-size: 17px;
                border-radius: 5px;
                height: 40px;
                border: 2px solid var(--secondary-text-color);
      
                /* margin-bottom: 10px; */
                &::placeholder {
                  color: var(--placeholder-text-color);
                }
                option {
                  font-size: 15px;
                  color: var(--secondary-text-color);
                }
              }
            }
            p {
              text-align: end;
              font-size: 18px;
              margin: 5px;
            }
      
            .btt {
              background-color: var(--button-color);
              color: var(--secondary-text-color);
              padding: 7px 10px;
              font-weight: 500;
              /* height: 35px; */
              border: none;
              cursor: pointer;
              border-radius: 7px;
              /* width: 150px; */
              font-size: 18px;
            }
            .btt:hover {
              color: var(--primary-text-color);
            }
          }
        }
      }
 