/* Modal Styles */
.modals {
   
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Center the modal */
    text-shadow: 0 0 black;
    box-shadow: 0 0px 5px;
    width: 350px; /* Full width */
    height: 220px; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: var(--primary-background); /* Black w/ opacity */
    border-radius: 20px;
  }
  
  /* Modal Content */
  .modals-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 10px;
    /* border: 1px solid #888; */
    width: 85%; /* Could be more or less, depending on screen size */
  }
  
  /* Modal Header */
  .modals-header {
    padding: 10px ;
    /* background-color: #f5f5f5; */
    display: flex;
    justify-content: space-between;
    /* box-shadow: 0 4px 5px; */
    width: 96%;
    align-items: center;

    .modals-title{
        font-size: 22px;
        margin: 0px;
        color: var(--secondary-text-color);


    }
    .cancelbtts{
        background-color: unset;
        border: unset;
    }
  }
  
  /* Modal Body */
  .modals-body {
    padding: 10px 16px;
    .profiledetails{

        form{
            display: flex;
            flex-direction: column;
            align-items: center;
           
        justify-content: center;
    
       
            .formsectionwrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            /* align-items: center; */
            position: relative;
            .labelwraper{
               
                label{
                    font-size: 20px;
                    /* padding: 10px; */
                    text-align: start;
                    color: var(--secondary-text-color);
                    font-weight: 400;
        
                }
        
    
            }
                    
            
           
    
            select{
                padding: 5px 10px;
                width: 250px;
                border:none;
                font-size: 17px;
                border-radius: 10px;
                height: 40;
                border: 2px solid  var(--secondary-text-color) ;
         
                &::placeholder{
                    color: var(--placeholder-text-color);
                }
                option{
                    
                    font-size: 15px;
                    color: var(--secondary-text-color);
    
                }
            }
            
    
            .error-message{
                color: var(--error-text-color);
                font-size: 12px;
                margin-top: 5px;
                text-align: left;
                /* min-height: 20px; */
              padding: 5px; 
              top: 3px;
              right: -170px;
              width: 150px;
              /* position: absolute; */
            
            
            }
            
            }
        }
    }


  }
  
  /* Modal Footer */
  .modals-footer {
    padding: 10px 16px;
    /* background-color: #f5f5f5; */
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  /* Close Button */
  .btns {
    padding: 5px 10px;
    color: var(--primary-text-color);
    font-size: 18px;
    /* background-color: var( --button-color-second); */
    border: unset;
    border-radius: 5px;
    color: var(--secondary-text-color);

    
  }
  
  .btns:hover,
  .btns:focus {
  color: var(--primary-text-color);
    text-decoration: none;
    cursor: pointer;
    opacity: 1.25;
  }
  