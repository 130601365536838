.salereport {
  background-color: var(--primary-background);
  min-height: 100vh;
}
.salereportwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .salereportheading {
    h3 {
      font-size: 30px;
      font-weight: 600;
      margin: 0px;
      color: var(--secondary-text-color);
      padding: 20px;
    }
  }
  .button {
    width: 75%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 0px 20px;
    /* margin-bottom: 10px; */

    .btt {
      background-color: var(--button-color);
      color: var(--primary-text-color);
      padding: 7px 10px;
      font-weight: bold;
      text-align: center;
      /* height: 30px; */
      border: none;
      cursor: pointer;
      border-radius: 7px;
      width: 130px;
      font-size: 18px;
      &::placeholder {
        color: var(--primary-text-color);
        font-weight: 700;
      }
    }
  }

  .salereportcontainer {
    /* background-color: var(--secondary-background); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 76%;
    border-radius: 0px;
    margin: 20px 0px;
    gap: 10px;
    box-shadow: 0 0 5px;

    .latestsalereport {
      width: 100%;
      display: flex;
      justify-content: center;

      .latestsalereportwrapper {
        color: var(--fourth-text-color);
        width: 100%;

        table {
          /* border: 2px solid ; */
          /* background-color: var(--primary-background); */
          /* border-radius: 10px; */
          border-collapse: collapse;
          /* box-shadow: 0 4px 16px rgba(8, 15, 37, 6%); */

          th {
            font-size: 20px;
            font-weight: unset;
            color: var(--secondary-text-color);
            background-color: var(--third-background);
            width: 220px;
          }
          td {
            font-size: 15px;
            color: var(--secondary-text-color);
            text-align: center;
            /* width: 220px; */
          }
        }
        table,
        th,
        td {
          border: 1px solid #d6d6d6;
          /* border-right:2px solid black ; */
          border-collapse: collapse;
          padding: 5px 0px;
          /* border-radius: 10px; */
        }
      }
    }
  }

  .party-heading {
    color: var(--secondary-text-color);
    font-size: 20px;
    text-align: start;
    width: 75%;
    margin: 0px;
  }
}
