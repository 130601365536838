.unauthorised_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
}

.unauthorised_heading {
    font-size: 2rem;
    color: #dc3545; /* Bootstrap's danger color */
}

.unauthorised_message {
    font-size: 1.2rem;
    margin: 20px 0;
}

.unauthorised_button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff; /* Bootstrap's primary color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.unauthorised_button:hover {
    background-color: #0069d9; /* Bootstrap's primary hover color */
}
