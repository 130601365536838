.wrappers {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  min-height: 100vh;
  .detailswrapper {
    h3 {
      color: var(--secondary-text-color);
      text-align: center;
      font-size: 30px;
      margin-bottom: 10px;
    }
    .details {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      .detailsinfo {
        display: flex;
        flex-direction: column;
        width: 80vw;
        gap: 20px;
      }
      .detailsheading {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 10px;
        p {
          font-size: 20px;
          color: var(--secondary-text-color);
          margin: 0px;
          font-weight: bold;
        }
        .btt {
          background-color: var(--button-color);
          font-weight: 600;
          /* height: 35px; */
          cursor: pointer;
          width: 165px;

          padding: 5px 10px;
          font-size: 18px;
          /* background-color: var( --button-color-second); */
          border: unset;
          border-radius: 5px;
          color: var(--secondary-text-color);
        }
        .btt:hover,
        .btt:focus {
          color: var(--primary-text-color);
          text-decoration: none;
          cursor: pointer;
          opacity: 1.25;
        }
      }
      .detailscontent {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .cashdetails {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          /* background-color: var(--third-background); */
          border-radius: 10px;

          .cashflow {
            width: 350px;
            padding: 0px 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
              color: var(--secondary-text-color);
              margin: 0px 0px 15px 0px;
              font-size: 20px;
              text-align: start;
              width: 100%;
            }
            .counter {
              color: var(--secondary-text-color);
              text-align: start;
              font-size: 20px;
              margin-bottom: 10px;
              margin-top: 0px;
              width: max-content;
            }
            table {
              border: 2px solid var(--secondary-text-color) !important ;
              /* background-color:rgb(8, 15, 37); */
              /* border-radius: 10px; */
              border-collapse: collapse;
              /* box-shadow: 0 4px 16px rgba(8, 15, 37, 6%); */

              th {
                font-size: 20px;
                font-weight: unset;
                color: var(--secondary-text-color);
                background-color: var(--third-background);
              }
              td {
                font-size: 15px;
                color: var(--secondary-text-color);
                text-align: center;
                font-weight: 500;
              }
            }
            table,
            th,
            td {
              border: 1px solid var(--secondary-text-color);
              /* border-right:2px solid black ; */
              border-collapse: collapse;
              padding: 5px 10px;
              /* border-radius: 10px; */
            }
          }

          .partydetails {
            width: max-content;
            padding: 0px 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2 {
              color: var(--secondary-text-color);
              margin: 0px 0px 15px 0px;
              font-size: 20px;
              width: 100%;
            }
            .partyname {
              color: var(--secondary-text-color);
              text-align: start;
              font-size: 20px;
              margin-bottom: 10px;
              margin-top: 0px;
              width: 100%;
            }
            table {
              border: 2px solid var(--secondary-text-color) !important;
              th {
                font-size: 20px;
                color: var(--secondary-text-color);
                font-weight: unset;
                background-color: var(--third-background);
              }
              td {
                font-size: 15px;
                color: var(--secondary-text-color);
                text-align: center;
                font-weight: 500;
              }
            }
            table,
            th,
            td {
              border: 1px solid var(--secondary-text-color);
              border-collapse: collapse;
              padding: 5px 10px;
            }
          }
        }

        .ticketdetails {
          display: flex;
          flex-direction: column;
          width: 100%;
          h2 {
            color: var(--secondary-text-color);
            margin: 0px 0px 15px 10px;
            font-size: 20px;
          }
          table {
            border: 2px solid var(--secondary-text-color) !important;
            border-collapse: collapse;
            width: 100%;

            th {
              font-size: 18px;
              color: var(--secondary-text-color);
              font-weight: unset;
              background: var(--third-background);
            }
            td {
              font-size: 15px;
              color: var(--secondary-text-color);
              padding: 5px 10px;
              text-align: center;
              font-weight: 500;
            }
          }

          table,
          th,
          td {
            /* background-color: var(--third-background); */
            border: 1px solid var(--secondary-text-color);

            /* border: 1px solid #eea47f; */
            border-collapse: collapse;
          }
        }
      }
    }
  }
}

.addreport {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  padding: 30px;
  width: 100%;
  background-color: var(--primary-background);

  .addwrapper {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 20px;
    /* background-color: var(--secondary-background); */
    padding: 20px 30px;
    border-radius: 20px;
    h2 {
      color: var(--secondary-text-color);
      margin: 0px;
      text-align: center;
    }

    .addreportwrapper {
      p {
        text-align: start;
        font-size: 25px;
        color: var(--secondary-text-color);
        margin: 10px;
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;

        .formsectionwrapper {
          display: flex;
          flex-direction: row;
          width: 450px;
          align-items: center;
          position: relative;
          .labelwraper {
            display: flex;
            justify-content: space-between;
            width: 230px;
            label {
              font-size: 20px;
              padding: 10px;
              text-align: start;
              color: var(--secondary-text-color);
              font-weight: 400;
            }
          }

          input {
            padding: 10px;
            width: 200px;
            border: none;
            font-size: 17px;
            border-radius: 10px;
            height: 15px;
            cursor: pointer;
            /* width: 30vw; */
            border: 2px solid var(--secondary-text-color);
            /* margin: auto; */
            &::placeholder {
              color: var(--placeholder-text-color);
              font-weight: 500;
            }
          }

          select {
            padding: 5px 10px;
            width: 225px;
            border: none;
            font-size: 17px;
            border-radius: 10px;
            height: 40;
            border: 2px solid var(--secondary-text-color);
            /* margin-bottom: 10px; */
            &::placeholder {
              color: var(--placeholder-text-color);
            }
            option {
              font-size: 15px;
              color: var(--secondary-text-color);
            }
          }

          .error-message {
            color: var(--error-text-color);
            font-size: 12px;
            margin-top: 5px;
            text-align: left;
            /* min-height: 20px; */
            padding: 5px;
            top: 3px;
            right: -170px;
            width: 150px;
            /* position: absolute; */
          }
        }
      }
    }

    .ticketcollector {
      p {
        text-align: start;
        font-size: 25px;
        color: var(--secondary-text-color);
        margin: 10px;
      }
      .radiowrapperclass {
        display: flex;
        align-items: center;
        justify-content: center;
        .label {
          width: 410px;
          display: flex;
          justify-content: space-between;
          label {
            font-size: 20px;
            line-height: 25px;
            font-weight: 600px;
          }
          .dot {
            display: block;
          }
        }

        .radiowrapper {
          /* display: flex;
          gap: 20px; */
          height: 15px;
          p {
            display: flex;
            gap: 5px;
            font-size: 20px;
            line-height: 25px;
            font-weight: 600px;
            margin: 0px;
            input {
              margin: 0px;
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;

        .formsectionwrapper {
          display: flex;
          flex-direction: row;
          width: 450px;
          align-items: center;
          position: relative;
          .labelwraper {
            display: flex;
            justify-content: space-between;
            width: 230px;
            label {
              font-size: 20px;
              padding: 10px;
              text-align: start;
              color: var(--secondary-text-color);
              font-weight: 400;
            }
          }

          input {
            padding: 10px;
            width: 200px;
            border: none;
            font-size: 17px;
            border-radius: 10px;
            height: 15px;
            cursor: pointer;
            /* width: 30vw; */
            border: 2px solid var(--secondary-text-color);
            /* margin: auto; */
            &::placeholder {
              color: var(--placeholder-text-color);
            }
          }

          select {
            padding: 5px 10px;
            width: 225px;
            border: none;
            font-size: 17px;
            border-radius: 10px;
            height: 40px;
            border: 2px solid var(--secondary-text-color);
            cursor: pointer;
            /* margin-bottom: 10px; */
            &::placeholder {
              color: var(--placeholder-text-color);
            }
            option {
              font-size: 15px;
              color: var(--secondary-text-color);
            }
          }
          .error-message {
            color: var(--error-text-color);
            font-size: 12px;
            margin-top: 5px;
            text-align: left;
            /* min-height: 20px; */
            padding: 5px;
            top: 3px;
            right: -190px;
            width: 170px;
            /* position: absolute; */
          }
        }
      }

      .addticketdetails {
        display: flex;
        justify-content: flex-end;
        margin-right: 7vw;
        padding: 10px;

        .btt {
          background-color: var(--button-color);
          color: var(--secondary-text-color);
          padding: 5px;
          font-weight: 600;
          height: 35px;
          border: none;
          cursor: pointer;
          border-radius: 10px;
          width: 60px;
          font-size: 18px;
          margin: 0px 10px;
        }
        .btt:hover {
          color: var(--primary-text-color);
        }
      }
    }

    .btt {
      background-color: var(--button-color);
      color: var(--secondary-text-color);
      padding: 5px;
      font-weight: 600;
      height: 35px;
      border: none;
      cursor: pointer;
      border-radius: 10px;
      width: 125px;
      font-size: 18px;
      margin-left: auto;
      margin-right: auto;
    }
    .btt:hover {
      color: var(--primary-text-color);
    }
  }
}

.horizontaldivider {
  height: 2px;
  background: var(--secondary-text-color);
  width: 80%;
  margin-top: 10px;
}

@media (max-width: 850px) {
  .wrappers {
    .detailswrapper {
      .details {
        .detailscontent {
          .cashdetails {
            flex-direction: column;
            .cashflow {
              width: 90%;
              padding: 0px 4%;
            }
            .partydetails {
              width: 90%;
              padding: 0px 4%;
            }
          }
          .ticketdetails {
            padding: 0px 4%;
            width: 90%;
            h2 {
              margin: 0px 0px 15px 25px;
            }
            .tables {
              width: 100%;
              display: flex;
              justify-content: center;
              table {
                display: flex;
                flex-direction: row;
                width: max-content;
                justify-content: center;
                /* /* border-width: initial; */
                border-collapse: collapse;
                tr {
                  flex-direction: column;
                  display: flex;

                  th {
                    padding: 5px 5px;
                    /* border:1px solid var(--secondary-text-color); */
                    font-size: 15px;
                  }
                  td {
                    font-size: 12px;
                    padding: 7px 5px;
                  }
                }
                /* td{
                flex-direction: column;
            display: flex;
            } */
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .wrappers {
      .detailswrapper {
        .details {
          .detailsinfo {
            width: 98vw;
          }
        }
      }
    }
  }
}

@media (min-width: 850px) and (max-width: 1050px) {
  .wrappers {
    .detailswrapper {
      .details {
        .detailsinfo {
          width: 96vw;
          padding: 0px 20px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .addreport {
    width: 85%;
    .addwrapper {
      width: 100%;
      padding: 0px;
      .fa-arrow-left {
        margin-left: -10px;
      }
      .addreportwrapper {
        p {
          margin-left: -10px;
        }
        form {
          gap: 10px;
          .formsectionwrapper {
            width: 90%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .labelwraper {
              label {
                padding: 0px;
              }
              .dotlabel {
                display: none;
              }
            }
            input {
              width: 250px;
            }
            select {
              width: 270px;
              height: 40px;
            }
            .error-message {
              top: 55px;
              left: 100px;
              text-align: right;
            }
          }
        }
      }
      .ticketcollector {
        p {
          margin-left: -10px;
        }

        .radiowrapperclass {
          display: flex;
          flex-direction: row-reverse;
          padding: 10px 0px;
          gap: 5px;
          align-items: center;
          justify-content: center;
          .label {
            width: 330px;
            display: flex;
            justify-content: space-between;
            label {
              font-size: 20px;
              line-height: 25px;
              font-weight: 600px;
            }
            .dot {
              display: none;
            }
          }

          .radiowrapper {
            /* display: flex;
            gap: 20px; */
            p {
              display: flex;
              gap: 5px;
              font-size: 20px;
              line-height: 25px;
              font-weight: 600px;
            }
          }
        }

        form {
          gap: 10px;
          .formsectionwrapper {
            width: 90%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .labelwraper {
              label {
                padding: 0px;
              }
              .dotlabel {
                display: none;
              }
            }
            input {
              width: 250px;
            }
            select {
              width: 270px;
              height: 40px;
            }
            .error-message {
              top: 55px;
              left: 100px;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .addticketdetails {
    .btt {
      margin: 6px 10px;
    }
  }
}
