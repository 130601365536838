.loginform {
  background: var(--primary-background);
  text-align: center;
  display: flex;
  justify-content: center;
  height: 92vh;
  align-items: center;
  .formwrapper {
    width: 465px;
    /* border: 1px solid black; */
    background-color: var(--secondary-background);
    /* margin-top: 10vw; */
    border-radius: 25px;
    box-shadow: 0 0px 5px;

    .otherlogin {
      font-size: 18px;
      text-align: start;

      /* padding-left: 20px; */
      padding: 20px 20px;
      p {
        margin: 5px;
        color: var(--secondary-text-color);
      }
    }
  }
  .loginformwraper {
    padding: 20px;
  }
  .title {
    font-size: 30px;
    color: var(--secondary-text-color);
    margin: 0px;
  }
  .subtitle {
    font-size: 15px;
    color: var(--secondary-text-color);
    padding: 0px 15px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    /* width: 100%; */
    padding: 0px 20px;
    margin: auto;

    .input-field {
      display: flex;
      flex-direction: column;
      position: relative;

      label {
        font-size: 20px;
        /* padding: 10px 0px; */
        font-weight: 400;
        text-align: start;
        color: var(--secondary-text-color);
      }

      input {
        padding: 10px;
        border: 2px solid var(--secondary-text-color);
        font-size: 17px;
        border-radius: 5px;
        height: 20px;
        /* width: 30vw ;
            /* margin: auto; */
        &::placeholder {
          color: var(--placeholder-text-color);
        }
      }
      .input-container {
        position: relative;
      }

      .input-container span {
        position: absolute;
        right: 10px; /* Adjust the distance from the right side as needed */
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .input-container input {
        padding: 10px;
        border: 2px solid var(--secondary-text-color);
        font-size: 17px;
        border-radius: 5px;
        height: 20px;
        width: 360px;
        /* width: 30vw ;
            /* margin: auto; */
        &::placeholder {
          color: var(--placeholder-text-color);
        }
      }
      select {
        padding: 8px 10px;
        border: none;
        font-size: 17px;
        border-radius: 10px;
        height: 40px;
        border-bottom: 1px solid #a7bcff;
        margin-bottom: 10px;
        &::placeholder {
          color: var(--placeholder-text-color);
        }
        option {
          font-size: 15px;
          color: var(--primary-text-color);
        }
      }
    }
    p {
      text-align: end;
      font-size: 18px;
      margin: 5px;
      color: var(--secondary-text-color);
      text-decoration: underline;
      text-decoration-color: var(--button-color);
      margin: -10px 0px 0px 0px;
    }

    .btt {
      background-color: var(--button-color);
      color: var(--secondary-text-color);
      padding: 7px 10px;
      font-weight: 600;
      /* height: 35px; */
      border: none;
      cursor: pointer;
      border-radius: 7px;
      width: 70px;
      font-size: 18px;
    }
    .btt:hover {
      color: var(--primary-text-color);
    }
  }
}

.error-messages {
  color: var(--error-text-color);
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
  /* min-height: 20px; */
  padding: 5px;
  top: 60px;
  width: max-content;
  position: absolute;
}
.custom-toast-body {
  color: var(--fourth-text-color);
  /* background-color: var(--secondary-background); */
}

@media (max-width: 750px) {
  .loginform {
    .formwrapper {
      width: 350px;

      form {
        .input-field {
          .input-container input {
            width: 250px;
          }
        }
      }
    }
  }
}
