.uploadfiles {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  min-height: 100vh;
  overflow: auto;
  .uploadwrapper {
    /* width: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    .heading {
      color: var(--secondary-text-color);
      text-align: center;
      font-size: 30px;
      font-weight: 600;

      width: 75%;
      p {
        margin: 10px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 75%;
      gap: 15px;
      .fileinput {
        display: flex;
        align-items: center;
        padding: 0px 10%;
        justify-content: center;

        .label {
          width: 25%;
          display: flex;
          justify-content: space-between;

          label {
            font-size: 20px;
            /* padding: 10px; */
            text-align: start;
            color: var(--secondary-text-color);
          }
        }

        .inputtype {
          position: relative;

          input {
            padding: 10px;
            width: 200px;
            border: none;
            font-size: 17px;
            border-radius: 10px;
            height: 15px;
            /* width: 30vw; */
            border: 2px solid var(--secondary-text-color);
            /* margin: auto; */
            &::placeholder {
              color: var(--placeholder-text-color);
              font-weight: 500;
            }
          }

          select {
            padding: 8px 10px;
            width: 225px;
            border: none;
            font-size: 17px;
            border-radius: 10px;
            height: 40;
            border: 2px solid var(--secondary-text-color);
            /* margin-bottom: 10px; */
            &::placeholder {
              color: var(--placeholder-text-color);
            }
            option {
              font-size: 15px;
              color: var(--secondary-text-color);
            }
          }

          .error-message {
            color: var(--error-text-color);
            font-size: 12px;
            margin-top: 5px;
            text-align: left;
            /* min-height: 20px; */
            padding: 5px;
            top: 3px;
            right: -175px;
            width: 160px;
            /* position: absolute; */
          }
        }
      }
      .button {
        display: flex;
        justify-content: center;
        .btt {
          background-color: var(--button-color);
          color: var(--secondary-text-color);
          padding: 7px 10px;
          font-weight: 500;
          /* height: 35px; */
          border: none;
          cursor: pointer;
          border-radius: 7px;
          width: max-content;
          font-size: 18px;
        }
        .btt:hover {
          color: var(--primary-text-color);
        }
      }
    }
  }
}

.uploaddetails {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  min-height: 100vh;
  overflow: auto;
  .uploadwrapper {
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    .contercontent {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading {
        color: var(--secondary-text-color);
        text-align: left;
        font-size: 30px;
        font-weight: 600;

        width: 75%;
        p {
          margin: 0px;
          text-decoration: underline;
        }
      }
      .counterbtt {
        .btt {
          background-color: var(--button-color);
          font-weight: 600;
          /* height: 35px; */
          cursor: pointer;
          width: 165px;

          padding: 5px 10px;
          font-size: 18px;
          /* background-color: var( --button-color-second); */
          border: unset;
          border-radius: 5px;
          color: var(--secondary-text-color);
        }
        .btt:hover,
        .btt:focus {
          color: var(--primary-text-color);
          text-decoration: none;
          cursor: pointer;
          opacity: 1.25;
        }
      }
    }

    .counteritem {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      /* justify-content: space-betwee;  */
      gap: 20px;

      .colcounter {
        width: calc(
          (100% - 40px) / 3
        ); /* Calculate width for 3 items in a row */
        display: flex;
        justify-content: center;
      }
      @media (max-width: 1100px) {
        .colcounter {
          width: calc((100% - 40px) / 2); /* Calculate width for 2 items in a row on smaller screens */
        }
      }
    
      @media (max-width: 800px) {
        .colcounter {
          width: calc(100% - 40px); /* Calculate width for 1 item in a row on even smaller screens */
        }
      }
    }
  }
}

.counteritemwrapper {
  width: 330px;
  box-shadow: 0 0 5px;
  padding: 20px 15px;
  border-radius: 10px;

  .counteritemcontent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      font-size: 16px;
      margin: 0px;
      line-height: 18px;
      font-weight: 600;
      display: flex;
      gap: 5px;

      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.addemployeewrapper{
.loginformwraper{

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    gap: 15px;
    .fileinput {
      display: flex;
      flex-direction: column;
      padding: 0px 10%;
      justify-content: flex-start;
      gap: 5px;

      

        label {
          font-size: 20px;
          /* padding: 10px; */
          text-align: start;
          color: var(--secondary-text-color);
        }
      

      .inputtype {
        position: relative;

        input {
          padding: 10px;
          width: 200px;
          border: none;
          font-size: 17px;
          border-radius: 10px;
          height: 15px;
          /* width: 30vw; */
          border: 2px solid var(--secondary-text-color);
          /* margin: auto; */
          &::placeholder {
            color: var(--placeholder-text-color);
            font-weight: 500;
          }
        }

        select {
          padding: 8px 10px;
          width: 225px;
          border: none;
          font-size: 17px;
          border-radius: 10px;
          height: 40;
          border: 2px solid var(--secondary-text-color);
          /* margin-bottom: 10px; */
          &::placeholder {
            color: var(--placeholder-text-color);
          }
          option {
            font-size: 15px;
            color: var(--secondary-text-color);
          }
        }

        .error-message {
          color: var(--error-text-color);
          font-size: 12px;
          margin-top: 5px;
          text-align: left;
          /* min-height: 20px; */
          padding: 5px;
          top: 3px;
          right: -110px;
          width: 160px;
          /* position: absolute; */
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      .btt {
        background-color: var(--button-color);
        color: var(--secondary-text-color);
        padding: 7px 10px;
        font-weight: 500;
        /* height: 35px; */
        border: none;
        cursor: pointer;
        border-radius: 7px;
        width: max-content;
        font-size: 18px;
      }
      .btt:hover {
        color: var(--primary-text-color);
      }
    }
  }
}
}
