.navbar {
  height: 50px;
  background-color: var(--secondary-background);

  .navcontainer {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
    font-size: 18px;
    height: 50px;

    .navbar-brand {
      img {
        width: 100px;
        height: 70px;
      }
    }
  }

  .navbarbtt {
    height: 25px;
    display: none;
  }

  .navlist {
    display: block;
    gap: 25px;
    /* position: absolute;
      top: 50px; /* adjust this value based on your navbar height */
    /*left: 0;
      width: 100%;
   */
    ul {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .navitems {
      display: block;

      .navlink {
        text-decoration: none;
        color: var(--secondary-text-color);

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}

.profile {
  /* margin-top: 4px; */
  display: flex;
  gap: 10px;
  width: 100px;
  justify-content: flex-end;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid black;
  }
}

.navlink.active {
  font-weight: bold;
}

.navbar-brand img {
  background-color: transparent; /* Set background color of the SVG to transparent */
}

.log {
  background-color: var(--button-color);
  color: var(--secondary-text-color);
  padding: 5px;
  font-weight: 600;
  height: 35px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  /* width: 60px; */
  font-size: 18px;
  margin: 0px 10px;
}
.log:hover {
  color: var(--primary-text-color);
}

@media (max-width: 700px) {
  .navcontainer {
    .navbarbtt {
      margin-top: 5px;
      display: block;
    }
    .navlist {
      display: none;

      ul {
        margin: 0px;
        padding: 0px;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  .navlist.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 60%;
    width: 40%;
    background-color: var(--secondary-background);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    padding: 10px 0px;
    z-index: 1;
  }
}
