.uploadfile {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  min-height: 100vh;
  overflow: auto;
  .uploadwrapper {
    /* width: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    .heading {
      color: var(--secondary-text-color);
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      width: 75%;
      p {
        margin: 10px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 75%;
      gap: 15px;
      .fileinput {
        display: flex;
        align-items: center;
        padding: 0px 10%;
        position: relative;

        .label {
          width: 30%;
          display: flex;
          justify-content: space-between;

          label {
            font-size: 20px;
            /* padding: 10px; */
            text-align: start;
            color: var(--secondary-text-color);
          }
        }

        input {
          padding: 5px 10px;
          width: 20vw;
          font-size: 17px;
          border-radius: 5px;
          /* height: 15px; */
          /* width: %; */
          border: 1px solid var(--secondary-text-color);
          background-color: #ffffff;
          /* margin: auto; */
          &::placeholder {
            color: var(--primary-text-color);
          }
        }
        .error-message {
          color: var(--error-text-color);
          font-size: 12px;
          margin-top: 5px;
          text-align: left;
          /* min-height: 20px; */
          padding: 5px;
          top: 3px;
          right: 230px;
          width: 150px;
          /* position: absolute; */
        }
      }
      .button {
        display: flex;
        justify-content: center;
        .btt {
          background-color: var(--button-color);
          color: var(--secondary-text-color);
          padding: 7px 10px;
          font-weight: 500;
          /* height: 35px; */
          border: none;
          cursor: pointer;
          border-radius: 7px;
          width: max-content;
          font-size: 18px;
        }
        .btt:hover {
          color: var(--primary-text-color);
        }
      }
    }
  }
}
