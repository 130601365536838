/* Modal Styles */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  right: 10px;
  top: 60px;
  text-shadow: 0 0 black;
  box-shadow: 0 0px 5px;
  width: 350px; /* Full width */
  height: 360px; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: var(--primary-background); /* Black w/ opacity */
  border-radius: 20px;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  /* border: 1px solid #888; */
  width: 85%; /* Could be more or less, depending on screen size */
}

/* Modal Header */
.modal-header {
  padding: 10px;
  /* background-color: #f5f5f5; */
  display: flex;
  justify-content: space-between;
  /* box-shadow: 0 4px 5px; */
  width: 96%;

  .modal-title {
    font-size: 25px;
    margin: 0px;
    color: var(--secondary-text-color);
  }
  .cancelbtt {
    background-color: unset;
    border: unset;
  }
}

/* Modal Body */
.modal-body {
  padding: 10px 16px;

  .profileimg {
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .profuledetails {
    p {
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* width: 75%; */
    /* gap: 15px; */
    .fileinput {
      display: flex;
      align-items: center;
      /* padding: 0px 10%; */
      position: relative;

      input {
        padding: 5px 10px;
        width: 170px;
        font-size: 17px;
        border-radius: 5px;
        /* height: 15px; */
        /* width: %; */
        border: 1px solid var(--secondary-text-color);
        background-color: #ffffff;
        /* margin: auto; */
        &::placeholder {
          color: var(--primary-text-color);
        }
      }
      .error-message {
        color: var(--error-text-color);
        font-size: 12px;
        margin-top: 5px;
        text-align: left;
        /* min-height: 20px; */
        padding: 5px;
        top: 3px;
        right: -35px;
        /* width: 150px; */
        /* position: absolute; */
      }
    }
  }
}

/* Modal Footer */
.modal-footer {
  padding: 10px 16px;
  /* background-color: #f5f5f5; */
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .btt {
    background-color: var(--button-color);
    color: var(--secondary-text-color);
    padding: 7px 10px;
    font-weight: 500;
    /* height: 35px; */
    border: none;
    cursor: pointer;
    border-radius: 7px;
    width: max-content;
    font-size: 18px;
  }
  .btt:hover {
    color: var(--primary-text-color);
  }
}

/* Close Button */
.btn {
  padding: 5px 10px;
  color: var(--primary-text-color);
  font-size: 18px;
  /* background-color: var( --button-color-second); */
  border: unset;
  border-radius: 5px;
  color: var(--secondary-text-color);
}

.btn:hover,
.btn:focus {
  color: var(--primary-text-color);
  text-decoration: none;
  cursor: pointer;
  opacity: 1.25;
}
