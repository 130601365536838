.modales{
    height: 400px;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Center the modal */
    text-shadow: 0 0 black;
    box-shadow: 0 0px 5px;
    width: 350px; /* Full width */
    overflow: auto; /* Enable scroll if needed */
    background-color: var(--primary-background); /* Black w/ opacity */
    border-radius: 20px;

    .modal-contents {
        background-color: #fefefe;
        padding: 20px;
        border-radius: 10px;
        /* border: 1px solid #888; */
        width: 85%; /* Could be more or less, depending on screen size */
      }
      
      /* Modal Header */
      .modal-headers {
        padding: 10px ;
        /* background-color: #f5f5f5; */
        display: flex;
        justify-content: space-between;
        /* box-shadow: 0 4px 5px; */
        width: 96%;
        align-items: center;
    
        .modal-title{
            font-size: 22px;
            margin: 0px;
            color: var(--secondary-text-color);
    
    
        }
        .cancelbtt{
            background-color: unset;
            border: unset;
        }
      }
      
      /* Modal Body */
      .modal-body {
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        table {
            /* border: 2px solid ; */
            /* background-color:rgb(8, 15, 37); */
            border-radius: 10px;
            border-collapse: collapse;
            /* box-shadow: 0 4px 16px rgba(8, 15, 37, 6%); */
  
            th {
              font-size: 20px;
              font-weight: unset;
              color: var(--secondary-text-color);
              background: var(--third-background);
            }
            td {
              font-size: 15px;
              color: var(--secondary-text-color);
              text-align: center;
            }
          }
          table,
          th,
          td {
            border: 1px solid #d6d6d6;
            /* border-right:2px solid black ; */
            border-collapse: collapse;
            padding: 5px 10px;
            /* border-radius: 10px; */
          }
        
      

    }
}