.saledetails {
  background-color: var(--primary-background);
  /* min-height: 100vh; */
  .heading {
    color: var(--secondary-text-color);
    text-align: left;
    font-size: 30px;
    padding-left: 11%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 30px 10px;
    .contentwrapper {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 0px;
      .button {
        display: flex;
        justify-content: flex-end;
        .btt {
          background-color: var(--button-color);
          color: var(--secondary-text-color);
          padding: 7px 10px;
          font-weight: 600;
          /* height: 35px; */
          border: none;
          cursor: pointer;
          border-radius: 7px;
          width: 70px;
          font-size: 18px;
        }
        .btt:hover {
          color: var(--primary-text-color);
        }
      }
      .ticketdetails {
        h2 {
          color: var(--secondary-text-color);
          font-size: 25px;
          margin-top: 0px;
        }
        table {
          /* border: 2px solid black; */
          border-radius: 10px;
          width: 100%;
          th {
            font-size: 20px;
            color: var(--secondary-text-color);
            font-weight: unset;
            background: var(--third-background);
          }
          td {
            font-size: 15px;
            color: var(--secondary-text-color);
            padding: 5px 10px;
            text-align: center;
          }
        }

        table,
        th,
        td {
          /* background-color: var(--third-background); */
          border: 1px solid #d6d6d6;

          /* border: 1px solid #eea47f; */
          /* border-radius: 10px; */
          border-collapse: collapse;
        }
      }

      .saledetailsreports {
        display: flex;
        justify-content: space-between;
        .cashflow {
          h2 {
            color: var(--secondary-text-color);
            font-size: 25px;
            margin-top: 0px;
          }

          table {
            /* border: 2px solid ; */
            /* background-color:rgb(8, 15, 37); */
            border-radius: 10px;
            border-collapse: collapse;
            /* box-shadow: 0 4px 16px rgba(8, 15, 37, 6%); */

            th {
              font-size: 20px;
              font-weight: unset;
              color: var(--secondary-text-color);
              background: var(--third-background);
            }
            td {
              font-size: 15px;
              color: var(--secondary-text-color);
              text-align: center;
            }
          }
          table,
          th,
          td {
            border: 1px solid #d6d6d6;
            /* border-right:2px solid black ; */
            border-collapse: collapse;
            padding: 5px 10px;
            /* border-radius: 10px; */
          }
        }

        .partydetails {
          width: max-content;
          padding: 0px 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h2 {
            color: var(--secondary-text-color);
            margin: 0px 0px 15px 0px;
            font-size: 20px;
            width: 100%;
          }
          .partyname {
            color: var(--secondary-text-color);
            text-align: start;
            font-size: 20px;
            margin-bottom: 10px;
            margin-top: 0px;
            width: 100%;
          }
          table {
            border: 2px solid var(--secondary-text-color) !important;
            th {
              font-size: 20px;
              color: var(--secondary-text-color);
              font-weight: unset;
              background-color: var(--third-background);
            }
            td {
              font-size: 15px;
              color: var(--secondary-text-color);
              text-align: center;
              /* font-weight: 500; */
            }
          }
          table,
          th,
          td {
            border: 1px solid var(--secondary-text-color);
            border-collapse: collapse;
            padding: 5px 10px;
          }
        }
      }
      .cashflow {
        h2 {
          color: var(--secondary-text-color);
          font-size: 25px;
          margin-top: 0px;
        }

        table {
          /* border: 2px solid ; */
          /* background-color:rgb(8, 15, 37); */
          border-radius: 10px;
          border-collapse: collapse;
          /* box-shadow: 0 4px 16px rgba(8, 15, 37, 6%); */

          th {
            font-size: 20px;
            font-weight: unset;
            color: var(--secondary-text-color);
            background: var(--third-background);
          }
          td {
            font-size: 15px;
            color: var(--secondary-text-color);
            text-align: center;
          }
        }
        table,
        th,
        td {
          border: 1px solid #d6d6d6;
          /* border-right:2px solid black ; */
          border-collapse: collapse;
          padding: 5px 10px;
          /* border-radius: 10px; */
        }
      }
    }
  }
}
