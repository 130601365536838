.addemployee {
  background-color: var(--primary-background);
  height: 100vh;
  overflow: auto;
  margin-top: 40px;
}
.addemployeewrapper {
  /* height: 88%; */
  width: 530px;
  background-color: var(--secondary-background);
  /* margin: 20px 20px; */
  /* display: flex; */
  border-radius: 20px;

  .loginformwraper {
    padding: 20px;
    box-shadow: 0 0 5px;
    border-radius: 25px;

    .title {
      font-size: 30px;
      text-align: center;
      color: var(--secondary-text-color);
      margin: 0px;
      padding-bottom: 20px;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* width: 80%; */
      padding: 0px 20px;
      gap: 15px;
      margin: auto;
      .inputfield {
        display: flex;
        flex-direction: column;
        position: relative;

        label {
          font-size: 20px;
          /* padding: 10px 0px 0px 0px; */
          text-align: start;
          color: var(--secondary-text-color);
        }

        input {
          padding: 10px;
          border: 2px solid var(--secondary-text-color);
          font-size: 17px;
          border-radius: 5px;
          height: 20px;
          &::placeholder {
            color: var(--placeholder-text-color);
          }
        }
        select {
          padding: 10px;
          border: none;
          font-size: 17px;
          border-radius: 5px;
          height: 40px;
          border: 2px solid var(--secondary-text-color);

          /* margin-bottom: 10px; */
          &::placeholder {
            color: var(--placeholder-text-color);
          }
          option {
            font-size: 15px;
            color: var(--secondary-text-color);
          }
        }
      }
      p {
        text-align: end;
        font-size: 18px;
        margin: 5px;
      }

      .btt {
        background-color: var(--button-color);
        color: var(--secondary-text-color);
        padding: 7px 10px;
        font-weight: 500;
        /* height: 35px; */
        border: none;
        cursor: pointer;
        border-radius: 7px;
        /* width: 150px; */
        font-size: 18px;
      }
      .btt:hover {
        color: var(--primary-text-color);
      }
    }
  }
}

.error-message {
  color: var(--error-text-color);
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
  /* min-height: 20px; */
  padding: 5px;
  top: 60px;
  width: max-content;
  position: absolute;
  right: 0px;
}

.employeedetails {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  min-height: 100vh;
  overflow: auto;
  .employeewrapper {
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .employeeHeading{
      color: var(--secondary-text-color);
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        text-decoration: underline;
    }
    .contercontent {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px;

      .heading {
        color: var(--secondary-text-color);
        text-align: left;
        font-size: 25px;
        font-weight: 600;
        display:flex;
        align-items: flex-end;
        gap: 10px;

        width: 75%;
        p {
          margin: 0px;
          text-decoration: underline;
        }
        .btt {
          background-color: #007bff; /* Blue background */
          color: white; 
          border: none; 
          border-radius: 4px; 
          padding: 10px 10px; 
          font-size: 16px; 
          cursor: pointer; 
          transition: background-color 0.3s, transform 0.3s;
        }
        
        /* Button hover state */
        .btt:hover {
          background-color: #0056b3; /* Darker blue on hover */
        }
        
        .btt:active {
          background-color: #00408d; 
          transform: scale(0.98);
        }
        
        .btt:focus {
          outline: none; 
          outline-offset:none ; 
        }
        
        .btt.selected {
          background-color: #0056b3; 
        }
      }
      .counterbtt {
        .btt {
          background-color: var(--button-color);
          font-weight: 600;
          /* height: 35px; */
          cursor: pointer;
          width: 165px;

          padding: 10px 10px;
          font-size: 18px;
          /* background-color: var( --button-color-second); */
          border: unset;
          border-radius: 5px;
          color: var(--secondary-text-color);
        }
        .btt:hover,
        .btt:focus {
          color: var(--primary-text-color);
          text-decoration: none;
          cursor: pointer;
          opacity: 1.25;
        }
      }
    }

    .counteritem {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      /* justify-content: space-betwee;  */
      width: 100%;
      gap: 20px;

      .colcounter {
        width: calc(
          (100% - 40px) / 3
        ); /* Calculate width for 3 items in a row */
        display: flex;
        justify-content: center;
      }
      @media (max-width: 1100px) {
        .colcounter {
          width: calc((100% - 40px) / 2); /* Calculate width for 2 items in a row on smaller screens */
        }
      }
    
      @media (max-width: 800px) {
        .colcounter {
          width: calc(100% - 40px); /* Calculate width for 1 item in a row on even smaller screens */
        }
      }
    }
  }
}