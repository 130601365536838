.party {
  background-color: var(--primary-background);
  .partydetails {
    padding: 2% 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      h2 {
        text-align: center;
        color: var(--secondary-text-color);
        font-size: 30px;
      }
    }

    .partycontent {
      background-color: var(--secondary-background);
      width: 95%;
      border-radius: 20px;
      padding: 20px;
      box-shadow: 0 0 5px;

      .partywrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 90%;
          gap: 20px;
          margin: auto;
          .partydetaillabel {
            display: flex;
            justify-content: center;
            align-items: center;

            .label {
              display: flex;
              justify-content: space-between;
              width: 65%;

              label {
                color: var(--secondary-text-color);
                font-size: 18px;
              }
            }

            .partyinput {
              position: relative;
              width: 40%;

              input {
                padding: 10px;
                border: none;
                font-size: 17px;
                border-radius: 10px;
                height: 20px;
                width: 90%;
                border: 1px solid var(--secondary-text-color);
                background: #ffffff;
                &::placeholder {
                  color: var(--placeholder-text-color);
                }
              }

              select {
                padding: 10px;
                font-size: 17px;
                border-radius: 10px;
                height: 40px;
                width: 98%;
                border: 1px solid var(--secondary-text-color);
                background: #ffffff;
                &::placeholder {
                  color: var(--primary-text-color);
                }
              }
            }

            .error-message {
              color: var(--error-text-color);
              font-size: 12px;
              margin-top: 5px;
              text-align: left;
              /* min-height: 20px; */
              padding: 5px;
              top: 30px;
              width: max-content;
              position: absolute;
              right: 0px;
            }
          }

          .radiowrapperclass {
            display: flex;
            /* justify-content: center; */
            align-items: center;
            /* margin: 0px 10px; */

            .label {
              display: flex;
              justify-content: space-between;
              width: 62%;
              label {
                color: var(--secondary-text-color);
                font-size: 18px;
              }
            }

            .partyinput {
              position: relative;
              width: 35%;
              .radiowrapper {
                display: flex;
                gap: 10px;
                justify-content: space-evenly;
                width: 90%;
                align-items: center;
                p {
                  color: var(--secondary-text-color);

                  font-size: 18px;
                  margin: 10px;
                  input {
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                  }
                }
              }
              .error-message {
                color: var(--error-text-color);
                font-size: 12px;
                margin-top: 5px;
                text-align: left;
                /* min-height: 20px; */
                padding: 5px;
                top: 30px;
                width: max-content;
                position: absolute;
                right: 0px;
              }
            }
          }
          .btt {
            background-color: var(--button-color);
            color: var(--secondary-text-color);
            padding: 5px;
            font-weight: 500;
            height: 35px;
            border: none;
            cursor: pointer;
            border-radius: 10px;
            width: max-content;
            font-size: 18px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
          }
          .btt:hover {
            color: var(--primary-text-color);
          }
        }
      }
    }
  }
}
